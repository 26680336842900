export function eccube(controller , scene){
	var parent = document.querySelector('.woocommerce-product-gallery');
	var child = document.querySelectorAll('.woocommerce-product-gallery__image');

	if(parent && child.length > 0){
		var elem = document.createElement('div');
		var imgdata = child[0].children[0].children[0].dataset['large_image'];
		elem.id = 'largeimg';
		elem.style.backgroundImage = 'url('+imgdata+')';
		parent.prepend(elem);

		for(var i = 0; i < child.length; i++){
			child[i].children[0].classList.add('no-barba');
			child[i].children[0].addEventListener('click',function(e){
				var src = this.children[0].dataset['large_image'];
				imgchange(src);
				e.preventDefault();
				return false;
			},false);
		}
		function imgchange(src){
			var target = document.getElementById(elem.id);
			target.style.backgroundImage = 'url('+src+')';
		}
	}
}