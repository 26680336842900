export function index(controller , scene){
    const tiltNode = [
        document.getElementById('tiltefect_01'),
        document.getElementById('tiltefect_02'),
        document.getElementById('tiltefect_03')
    ];
    for(let i = 0; i < tiltNode.length; i++){
        new TiltFx(
            tiltNode[i],{
                extraImgs : 4,
                opacity : 0.36,
                bgfixed : false,
                resetOnLeave : false,
                movement: { 
                    perspective : 1500, 
                    translateX : -40, 
                    translateY : -10, 
                    translateZ : -80, 
                    rotateY : -.1,
                }
            }
        )
    }
    const swiper = new Swiper('.swiper-container',{
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade',
        speed: 2000,
		loop: true,
        autoplay: {
            delay: 8000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
			clickable: true,
        },
    });
}